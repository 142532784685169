var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.configureLoading),expression:"configureLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"user-profile-entity-document-templates",attrs:{"element-loading-text":_vm.LoadingText,"element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[(!_vm.getIsMobile && !this.isADocument)?_c('el-table',{staticClass:"templates-table",attrs:{"total":_vm.total,"current-page":_vm.currentPage,"page-size":_vm.pageSize,"pagination-props":_vm.paginationProps,"data":_vm.documentTemplatesByTemplateId,"border":""},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"query-change":_vm.loadData,"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"Sent On","min-width":"120","prop":"created_at","id":"created_at","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row && scope.row.created_at)?_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm._f("globalDateTimeFormat")(scope.row.created_at))+" ")]):_vm._e()]}}],null,false,1940625678)}),_c('el-table-column',{attrs:{"label":"Tracking Status","prop":"document_tracking_status","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (
          scope.row.document_tracking_status &&
          scope.row.document_tracking_status.type
        )?[_c('p',{staticClass:"text-secondary-sent",staticStyle:{"font-family":"inter","font-size":"14px"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm._f("truncate")(scope.row.document_tracking_status.description,18, "..."))+" ")])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"prop":"document_status","label":"Document Status","id":"document_status","min-width":"120","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row && scope.row.document_status)?[(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'COMPLETED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Completed")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'VOIDED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Voided")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'SENT'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v(" Sent ")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'APPROVED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Approved")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'DECLINED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Declined")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'EXPIRED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Expired")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'DRAFT'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Draft")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'ARCHIVED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Archived")]):_vm._e()]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"Signed On","min-width":"82"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row)?[(
            scope.row &&
            scope.row.document_settings &&
            scope.row.document_settings.life_cycle_date &&
            scope.row.document_settings.life_cycle_date.signature_date
          )?_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm._f("globalDateFormat")(scope.row.document_settings.life_cycle_date.signature_date))+" ")]):_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("- -")])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"Completed At","prop":"completed_at","id":"completed_at","min-width":"120","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row)?[(scope.row && scope.row.completed_at)?_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm._f("globalDateTimeFormat")(scope.row.completed_at))+" ")]):_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("- -")])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"Actions","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row)?[_c('ul',[_c('div',{staticStyle:{"display":"flex"}},[_c('el-tooltip',{attrs:{"content":"View Document","placement":"top-start"}},[_c('li',[_c('el-button',{staticClass:"px-2",attrs:{"plain":"","type":"danger","size":"mini"},on:{"click":function($event){return _vm.onView(scope.row._id)}}},[_c('i',{staticClass:"el-icon-view"})])],1)]),(
                scope.row &&
                scope.row.document_status &&
                (scope.row.document_status == 'SENT' ||
                  scope.row.document_status == 'APPROVER') &&
                !scope.row.document_filling_status &&
                _vm.checkToOnlyResend(scope.row)
              )?_c('el-tooltip',{attrs:{"content":"Resend Document","placement":"top-start"}},[_c('li',[_c('el-button',{staticClass:"px-2",attrs:{"plain":"","type":"danger","size":"mini"},on:{"click":function($event){return _vm.resendDocLink(scope.row)}}},[_c('i',{staticClass:"el-icon-position"})])],1)]):(scope.row.document_status == 'COMPLETED')?_c('el-tooltip',{attrs:{"content":"Download Document","placement":"top-start"}},[_c('li',[_c('el-button',{staticClass:"px-2",attrs:{"plain":"","type":"danger","size":"mini"},on:{"click":function($event){return _vm.onDownload(scope.row)}}},[_c('i',{staticClass:"el-icon-download"})])],1)]):_vm._e(),_c('li',[_c('el-dropdown',[_c('img',{staticClass:"el-dropdown-link",attrs:{"src":require("@/assets/img/icons/more-icon.svg"),"alt":"icon"}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                      (_vm.checkPermissionForDocument(
                        scope.row,
                        'correctDocument'
                      ) &&
                        scope.row.document_status != 'VOIDED' &&
                        scope.row.document_status != 'COMPLETED' &&
                        scope.row.document_status != 'ARCHIVED' &&
                        scope.row.document_status != 'EXPIRED') ||
                      _vm.isfilledOrNot(scope.row)
                    )?_c('a',{on:{"click":function($event){return _vm.alertCorrectedDoc(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-document-checked"}),_vm._v(" Correct the document")])],1):_vm._e(),(
                      _vm.checkPermissionForDocument(
                        scope.row,
                        'cloneDocument'
                      ) &&
                      (scope.row.document_status == 'VOIDED' ||
                        scope.row.document_status == 'COMPLETED')
                    )?_c('a',{on:{"click":function($event){return _vm.EditVoidDocument(scope.row._id)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-document-copy"}),_vm._v(" Clone")])],1):_vm._e(),_c('a'),(
                      _vm.checkPermissionForDocument(scope.row, 'voidDocument') &&
                      scope.row &&
                      scope.row.document_status &&
                      scope.row.document_status != 'VOIDED' &&
                      scope.row.document_status != 'DECLINED' &&
                      scope.row.document_status != 'COMPLETED' &&
                      scope.row.document_status != 'EXPIRED' &&
                      scope.row.document_status != 'ARCHIVED'
                    )?_c('a',{on:{"click":function($event){return _vm.alertvoided(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-remove-outline"}),_vm._v("Void Document ")])],1):_vm._e(),(
                      _vm.checkPermissionForDocument(
                        scope.row,
                        'deleteDocument'
                      ) && scope.row.document_status != 'ARCHIVED'
                    )?_c('a',{on:{"click":function($event){return _vm.archiveDocument(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-delete"}),_vm._v("Delete")])],1):_vm._e(),(
                      scope.row &&
                      scope.row.document_status &&
                      scope.row.document_status === 'ARCHIVED'
                    )?_c('a',{on:{"click":function($event){return _vm.alertUndoDoc(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-refresh-left"}),_vm._v("Undo Delete ")])],1):_vm._e(),(
                      scope.row &&
                      scope.row.document_status &&
                      scope.row.document_status === 'ARCHIVED'
                    )?_c('a',{on:{"click":function($event){return _vm.permanentDocument(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-delete-solid"}),_vm._v("Permanent Delete ")])],1):_vm._e()])],1)],1)],1)])]:undefined}}],null,true)})],1):_vm._e(),(_vm.getIsMobile && !this.isADocument)?_c('el-table',{staticClass:"templates-table-is-mobile",attrs:{"total":_vm.total,"current-page":_vm.currentPage,"page-size":_vm.pageSize,"pagination-props":_vm.paginationProps,"data":_vm.documentTemplatesByTemplateId},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event},"query-change":_vm.loadData,"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"prop":"document_status","label":"Document Status","id":"document_status","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row && scope.row.document_status)?[(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'COMPLETED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Completed")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'VOIDED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Voided")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'SENT'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v(" Sent ")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'APPROVED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Approved")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'DECLINED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Declined")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'EXPIRED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Expired")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'DRAFT'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Draft")]):_vm._e(),(
            scope &&
            scope.row &&
            scope.row.document_status &&
            scope.row.document_status == 'ARCHIVED'
          )?_c('span',{class:scope.row.document_status.toLowerCase() + ' circle'},[_vm._v("Archived")]):_vm._e()]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"label":"Actions","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row)?[_c('ul',[_c('div',{staticStyle:{"display":"flex"}},[_c('el-tooltip',{attrs:{"content":"View Document","placement":"top-start"}},[_c('li',[_c('el-button',{staticClass:"px-2",attrs:{"plain":"","type":"danger","size":"mini"},on:{"click":function($event){return _vm.onView(scope.row._id)}}},[_c('i',{staticClass:"el-icon-view"})])],1)]),(
                scope.row &&
                scope.row.document_status &&
                (scope.row.document_status == 'SENT' ||
                  scope.row.document_status == 'APPROVER') &&
                !scope.row.document_filling_status &&
                _vm.checkToOnlyResend(scope.row)
              )?_c('el-tooltip',{attrs:{"content":"Resend Document","placement":"top-start"}},[_c('li',{staticStyle:{"margin-left":"-3px"}},[_c('el-button',{staticClass:"px-2",attrs:{"plain":"","type":"danger","size":"mini"},on:{"click":function($event){return _vm.resendDocLink(scope.row)}}},[_c('i',{staticClass:"el-icon-position"})])],1)]):(scope.row.document_status == 'COMPLETED')?_c('el-tooltip',{attrs:{"content":"Download Document","placement":"top-start"}},[_c('li',[_c('el-button',{staticClass:"px-2",attrs:{"plain":"","type":"danger","size":"mini"},on:{"click":function($event){return _vm.onDownload(scope.row)}}},[_c('i',{staticClass:"el-icon-download"})])],1)]):_vm._e(),_c('li',[_c('el-dropdown',[_c('img',{staticClass:"el-dropdown-link",staticStyle:{"margin-left":"-3px"},attrs:{"src":require("@/assets/img/icons/more-icon.svg"),"alt":"icon"}}),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                      (_vm.checkPermissionForDocument(
                        scope.row,
                        'correctDocument'
                      ) &&
                        scope.row.document_status != 'VOIDED' &&
                        scope.row.document_status != 'COMPLETED' &&
                        scope.row.document_status != 'ARCHIVED' &&
                        scope.row.document_status != 'EXPIRED') ||
                      _vm.isfilledOrNot(scope.row)
                    )?_c('a',{on:{"click":function($event){return _vm.alertCorrectedDoc(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-document-checked"}),_vm._v(" Correct the document")])],1):_vm._e(),(
                      _vm.checkPermissionForDocument(
                        scope.row,
                        'cloneDocument'
                      ) &&
                      (scope.row.document_status == 'VOIDED' ||
                        scope.row.document_status == 'COMPLETED')
                    )?_c('a',{on:{"click":function($event){return _vm.EditVoidDocument(scope.row._id)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-document-copy"}),_vm._v(" Clone")])],1):_vm._e(),_c('a'),(
                      _vm.checkPermissionForDocument(scope.row, 'voidDocument') &&
                      scope.row &&
                      scope.row.document_status &&
                      scope.row.document_status != 'VOIDED' &&
                      scope.row.document_status != 'DECLINED' &&
                      scope.row.document_status != 'COMPLETED' &&
                      scope.row.document_status != 'EXPIRED' &&
                      scope.row.document_status != 'ARCHIVED'
                    )?_c('a',{on:{"click":function($event){return _vm.alertvoided(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-remove-outline"}),_vm._v("Void Document ")])],1):_vm._e(),(
                      _vm.checkPermissionForDocument(
                        scope.row,
                        'deleteDocument'
                      ) && scope.row.document_status != 'ARCHIVED'
                    )?_c('a',{on:{"click":function($event){return _vm.archiveDocument(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-delete"}),_vm._v("Delete")])],1):_vm._e(),(
                      scope.row &&
                      scope.row.document_status &&
                      scope.row.document_status === 'ARCHIVED'
                    )?_c('a',{on:{"click":function($event){return _vm.alertUndoDoc(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-refresh-left"}),_vm._v("Undo Delete ")])],1):_vm._e(),(
                      scope.row &&
                      scope.row.document_status &&
                      scope.row.document_status === 'ARCHIVED'
                    )?_c('a',{on:{"click":function($event){return _vm.permanentDocument(scope.row)}}},[_c('el-dropdown-item',[_c('i',{staticClass:"el-icon-delete-solid"}),_vm._v("Permanent Delete ")])],1):_vm._e()])],1)],1)],1)])]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"expand-column-item"},[_c('div',{staticClass:"expand-column-item-label"},[_vm._v("Tracking Status:")]),_c('div',{staticClass:"expand-column-item-content"},[(scope.row && scope.row.created_at)?_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm._f("globalDateTimeFormat")(scope.row.created_at))+" ")]):_vm._e()])]),_c('div',{staticClass:"expand-column-item"},[_c('div',{staticClass:"expand-column-item-label"},[_vm._v("Sent On:")]),_c('div',{staticClass:"expand-column-item-content"},[(
                scope.row.document_tracking_status &&
                scope.row.document_tracking_status.type
              )?[_c('p',{staticClass:"text-secondary-sent",staticStyle:{"font-family":"inter","font-size":"14px"},attrs:{"slot":"reference"},slot:"reference"},[_vm._v(" "+_vm._s(_vm._f("truncate")(scope.row.document_tracking_status.description,18, "..."))+" ")])]:_vm._e()],2)]),_c('div',{staticClass:"expand-column-item"},[_c('div',{staticClass:"expand-column-item-label"},[_vm._v("Signed On:")]),_c('div',{staticClass:"expand-column-item-content",scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row)?[(
                  scope.row &&
                  scope.row.document_settings &&
                  scope.row.document_settings.life_cycle_date &&
                  scope.row.document_settings.life_cycle_date.signature_date
                )?_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm._f("globalDateFormat")(scope.row.document_settings.life_cycle_date.signature_date))+" ")]):_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("- -")])]:undefined}}],null,true)})]),_c('div',{staticClass:"expand-column-item"},[_c('div',{staticClass:"expand-column-item-label"},[_vm._v("Completed At:")]),_c('div',{staticClass:"expand-column-item-content",scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row)?[(scope.row && scope.row.completed_at)?_c('p',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm._f("globalDateTimeFormat")(scope.row.completed_at))+" ")]):_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("- -")])]:undefined}}],null,true)})])]}}],null,false,330096705)})],1):_vm._e(),_c('el-pagination',{class:this.isADocument ? 'custom-pagination' : 'templatesPagination',attrs:{"current-page":_vm.currentPage,"page-sizes":_vm.paginationProps,"page-size":_vm.pageSize,"layout":"prev, pager, next, jumper, sizes, total","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }