<!-- *** this is the child component for preview.vue file ***-->
<template>
  <section
    class="user-profile-entity-document-templates"
    v-loading.fullscreen.lock="configureLoading"
    :element-loading-text="LoadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- <el-input
              class="search"
              placeholder="Search"
              type="text"
              v-model="search_string"
              clearable
             
            >
            </el-input> -->
    <el-button
      v-if="
        currentEntity &&
        currentEntity.entity_type &&
        currentEntity.entity_type == 'INDIVIDUAL' &&
        !this.isADocument &&
        !getIsMobile
      "
      style="margin-left: 90%; padding: 13px 14px"
      type="primary"
      plain
      @click="uploadUserDocument"
      ><i class="el-icon-upload"></i> Upload</el-button
    >

    <div class="vue-data-table-default">
      <!-- *** side panel menu of templates and documents for that particular entity start here *** -->
      <el-tabs
        :style="
          this.isADocument ||
          (currentEntity &&
            currentEntity.isAccount &&
            currentEntity.entity_type == 'BUSINESS')
            ? 'margin-top:20px'
            : 'margin-top:-23px'
        "
        :class="getIsMobile ? 'mt-1' : ''"
        v-model="activeName"
        @tab-click="handleClick('Doc Templates', $event)"
      >
        <el-tab-pane label="Doc Templates" name="first">
          <el-tabs
            type="border-card"
            @tab-click="handleClickTab()"
            v-model="activeTabName"
          >
            <el-tab-pane
              :lazy="true"
              v-for="(val, index) in entityDocumentTemplates"
              :key="index"
              border
              :label="val.name"
              class="sidemenu"
            >
              <UserTemplatesTable
                v-if="sendDataToTable"
                :configurableId="val.configurable_document_id"
                :entityIdForTable="entityIdTable"
                :entityDataIdForTable="entityDataIdTable"
              />
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="Other Docs" name="second"> </el-tab-pane>
        <el-tab-pane label="Document Data" name="document_data"></el-tab-pane>
      </el-tabs>
      <el-row v-if="activeName == 'document_data'">
        <entity-documents-data
          :entityId="entityId"
          :entityDataId="entityDataId"
        >
        </entity-documents-data>
      </el-row>
      <el-row v-else>
        <el-table
          v-if="this.isADocument"
          :data="entityDocumentsNotIncludingTemplates"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @query-change="loadData"
          class="documentsTable"
          :header-cell-style="{ background: '#fafafa' }"
          border
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            label="Document Name"
            min-width="130"
            prop="name"
            id="name"
            sortable="custom"
          >
            <template slot-scope="scope">
              <p v-if="scope.row && scope.row.name" style="font-size: 14px">
                {{ scope.row.name | truncate(23, "...") }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="Sent On"
            min-width="130"
            prop="created_at"
            id="created_at"
            sortable="custom"
          >
            <template slot-scope="scope">
              <p
                v-if="scope.row && scope.row.created_at"
                style="font-size: 14px"
              >
                {{ scope.row.created_at | globalDateTimeFormat }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            label="Tracking Status"
            prop="document_tracking_status"
            min-width="150"
          >
            <template
              slot-scope="scope"
              v-if="
                scope.row.document_tracking_status &&
                scope.row.document_tracking_status.type
              "
            >
              <p
                slot="reference"
                style="font-family: inter; font-size: 14px"
                class="text-secondary-sent"
              >
                {{
                  scope.row.document_tracking_status.description
                    | truncate(25, "...")
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="document_status"
            label="Document Status"
            id="document_status"
            min-width="120"
            sortable="custom"
          >
            <template
              slot-scope="scope"
              v-if="scope.row && scope.row.document_status"
              style="font-size: 14px"
            >
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'COMPLETED'
                "
                :class="scope.row.document_status.toLowerCase() + ' circle'"
                >Completed</span
              >
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'VOIDED'
                "
                :class="scope.row.document_status.toLowerCase() + ' circle'"
                >Voided</span
              >
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'SENT'
                "
                :class="scope.row.document_status.toLowerCase() + ' circle'"
              >
                Sent
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'APPROVED'
                "
                :class="scope.row.document_status.toLowerCase() + ' circle'"
              >
                Approved
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'DECLINED'
                "
                :class="scope.row.document_status.toLowerCase() + ' circle'"
              >
                Declined
              </span>
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'EXPIRED'
                "
                :class="scope.row.document_status.toLowerCase() + ' circle'"
                >Expired</span
              >
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'ARCHIVED'
                "
                :class="scope.row.document_status.toLowerCase() + ' circle'"
                >Archived</span
              >
              <span
                v-if="
                  scope &&
                  scope.row &&
                  scope.row.document_status &&
                  scope.row.document_status == 'DRAFT'
                "
                :class="scope.row.document_status.toLowerCase() + ' circle'"
                >Draft</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="Completed At"
            prop="completed_at"
            id="completed_at"
            min-width="120"
            sortable="custom"
          >
            <template slot-scope="scope" v-if="scope.row">
              <p
                v-if="scope.row && scope.row.completed_at"
                style="font-size: 14px"
              >
                {{ scope.row.completed_at | globalDateTimeFormat }}
              </p>
              <p v-else>Not yet completed</p>
            </template>
          </el-table-column>
          <el-table-column label="Actions" width="190">
            <template slot-scope="scope" v-if="scope.row">
              <ul>
                <div style="display: flex">
                  <el-tooltip content="View Document" placement="top-start">
                    <li>
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="onView(scope.row._id)"
                      >
                        <i class="el-icon-view"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <el-tooltip
                    content="Resend Document"
                    placement="top-start"
                    v-if="
                      scope.row &&
                      scope.row.document_status &&
                      (scope.row.document_status == 'SENT' ||
                        scope.row.document_status == 'APPROVER') &&
                      !scope.row.document_filling_status &&
                      checkToOnlyResend(scope.row)
                    "
                  >
                    <li>
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="resendDocLink(scope.row)"
                      >
                        <i class="el-icon-position"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <el-tooltip
                    content="Download Document"
                    placement="top-start"
                    v-else-if="scope.row.document_status == 'COMPLETED'"
                  >
                    <li>
                      <el-button
                        plain
                        type="danger"
                        size="mini"
                        class="px-2"
                        @click="onDownload(scope.row)"
                      >
                        <i class="el-icon-download"></i>
                      </el-button>
                    </li>
                  </el-tooltip>
                  <li>
                    <!-- *** need to work on it *** -->
                    <el-dropdown>
                      <img
                        src="@/assets/img/icons/more-icon.svg"
                        alt="icon"
                        class="el-dropdown-link"
                      />
                      <el-dropdown-menu slot="dropdown">
                        <a
                          @click="alertCorrectedDoc(scope.row)"
                          v-if="
                            (checkPermissionForDocument(
                              scope.row,
                              'correctDocument'
                            ) &&
                              scope.row.document_status != 'VOIDED' &&
                              scope.row.document_status != 'COMPLETED' &&
                              scope.row.document_status != 'ARCHIVED' &&
                              scope.row.document_status != 'EXPIRED') ||
                            isfilledOrNot(scope.row)
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-document-checked"></i> Correct
                            the document</el-dropdown-item
                          >
                        </a>
                        <a
                          @click="EditVoidDocument(scope.row._id)"
                          v-if="
                            checkPermissionForDocument(
                              scope.row,
                              'cloneDocument'
                            ) &&
                            (scope.row.document_status == 'VOIDED' ||
                              scope.row.document_status == 'COMPLETED')
                          "
                        >
                          <el-dropdown-item
                            ><i class="el-icon-document-copy"></i>
                            Clone</el-dropdown-item
                          >
                        </a>
                        <a> </a>
                        <a
                          @click="alertvoided(scope.row)"
                          v-if="
                            checkPermissionForDocument(
                              scope.row,
                              'voidDocument'
                            ) &&
                            scope.row &&
                            scope.row.document_status &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'DECLINED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'EXPIRED' &&
                            scope.row.document_status != 'ARCHIVED'
                          "
                        >
                          <el-dropdown-item>
                            <i class="el-icon-remove-outline"></i>Void Document
                          </el-dropdown-item>
                        </a>
                        <a
                          v-if="
                            checkPermissionForDocument(
                              scope.row,
                              'deleteDocument'
                            ) && scope.row.document_status != 'ARCHIVED'
                          "
                          @click="archiveDocument(scope.row)"
                        >
                          <el-dropdown-item
                            ><i class="el-icon-delete"></i
                            >Delete</el-dropdown-item
                          >
                        </a>
                        <a
                          v-if="
                            scope.row &&
                            scope.row.document_status &&
                            scope.row.document_status === 'ARCHIVED'
                          "
                          @click="alertUndoDoc(scope.row)"
                        >
                          <el-dropdown-item>
                            <i class="el-icon-refresh-left"></i>Undo Delete
                          </el-dropdown-item>
                        </a>
                        <a
                          v-if="
                            scope.row &&
                            scope.row.document_status &&
                            scope.row.document_status === 'ARCHIVED'
                          "
                          @click="permanentDocument(scope.row)"
                        >
                          <el-dropdown-item>
                            <i class="el-icon-delete-solid"></i>Permanent Delete
                          </el-dropdown-item>
                        </a>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <!-- **** need to work on it *** -->
                  </li>
                </div>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-if="this.isADocument"
          :class="
            this.isADocument ? 'custom-pagination' : 'templatesPagination'
          "
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="paginationProps"
          :page-size="pageSize"
          layout="prev, pager, next, jumper, sizes, total"
          :total="total"
        >
        </el-pagination>
      </el-row>
      <!-- <div v-if="!getIsMobile">
        <el-menu
          class="el-menu-vertical-demo sidemenu-one list-group sidemenu"
          :style="this.isADocument ? 'height:90px' : 'width:230px'"
        >
          <el-tabs
            type="border-card"
            v-model="activeName"
            @tab-click="handleClick('Doc Templates', $event)"
          >
            <el-input
              class="search"
              placeholder="Search"
              v-model="search_string"
              clearable
              style="width:200px;margin-left:10px'
              "
            >
            </el-input>
            <el-tab-pane
              :label="getIsMobile ? 'Templates' : 'Doc Templates'"
              name="first"
            >
              <el-menu-item
                v-for="(val, index) in entityDocumentTemplates"
                :key="index"
                border
              >
                <div
                  v-if="!getIsMobile"
                  :class="getClass(val)"
                  @click="templateDocumentsSideMenu(val)"
                >
                  <img
                    class="ml-2 mr-1"
                    src="@/assets/img/icons/docs-file.svg"
                    alt="icon"
                    style="width: 20px"
                  />
                  {{ val.name | truncate(22, "...") }}
                </div>
              </el-menu-item>
              <br />
            </el-tab-pane>
            <el-tab-pane
              :label="getIsMobile ? 'Docs' : ' Other Docs'"
              name="second"
            >
            </el-tab-pane>
          </el-tabs>
        </el-menu>
      </div> -->
      <!-- *** side panel ends here ***  -->
      <!-- *** If user click on documents this table data will be displayed of that particular entity (table starts here) -->
      <!-- *** Documents table ends here *** -->
      <!--  *** Template documents table starts here *** -->

      <!-- <el-table
        class="templates-table"
        v-else-if="!getIsMobile && !this.isADocument"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationProps"
        @query-change="loadData"
        :data="documentTemplatesByTemplateId"
        border
        @sort-change="sortChange"
      >
        <el-table-column
          label="Sent On"
          min-width="120"
          prop="created_at"
          id="created_at"
          sortable="custom"
        >
          <template slot-scope="scope">
            <p v-if="scope.row && scope.row.created_at" style="font-size: 14px">
              {{ scope.row.created_at | globalDateTimeFormat }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="Tracking Status"
          prop="document_tracking_status"
          min-width="120"
        >
          <template
            slot-scope="scope"
            v-if="
              scope.row.document_tracking_status &&
              scope.row.document_tracking_status.type
            "
          >
            <p
              slot="reference"
              style="font-family: inter; font-size: 14px"
              class="text-secondary-sent"
            >
              {{
                scope.row.document_tracking_status.description
                  | truncate(18, "...")
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="document_status"
          label="Document Status"
          id="document_status"
          min-width="120"
          sortable="custom"
        >
          <template
            slot-scope="scope"
            v-if="scope.row && scope.row.document_status"
            style="font-size: 14px"
          >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'COMPLETED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Completed</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'VOIDED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Voided</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'SENT'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
            >
              Sent
            </span>
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'APPROVED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Approved</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'DECLINED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Declined</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'EXPIRED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Expired</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'DRAFT'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Draft</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'ARCHIVED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Archived</span
            >
          </template>
        </el-table-column>
        <el-table-column
            label="Expires at"
            width="190"
            prop="expires_at"
            sortable="custom"
          >
              <template
              slot-scope="scope"
                v-if="
                  scope.row &&
                  scope.row.document_status &&
                  (scope.row.document_status == 'SENT' ||
                  scope.row.document_status == 'APPROVED')
                "
              >
              <p
              v-if="scope.row && scope.row.completed_at"
              style="font-size: 14px"
            >
              {{ scope.row.expires_at | globalDateTimeFormat }}
            </p>
            <p v-else style="text-align: center">- -</p>
              </template>
          </el-table-column> 
        <el-table-column label="Signed On" min-width="82">
          <template slot-scope="scope" v-if="scope.row">
            <p
              v-if="
                scope.row &&
                scope.row.document_settings &&
                scope.row.document_settings.life_cycle_date &&
                scope.row.document_settings.life_cycle_date.signature_date
              "
              style="font-size: 14px"
            >
              {{
                scope.row.document_settings.life_cycle_date.signature_date
                  | globalDateFormat
              }}
            </p>
            <p v-else style="text-align: center">- -</p>
          </template>
        </el-table-column>
        <el-table-column
          label="Completed At"
          prop="completed_at"
          id="completed_at"
          min-width="120"
          sortable="custom"
        >
          <template slot-scope="scope" v-if="scope.row">
            <p
              v-if="scope.row && scope.row.completed_at"
              style="font-size: 14px"
            >
              {{ scope.row.completed_at | globalDateTimeFormat }}
            </p>
            <p v-else style="text-align: center">- -</p>
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="150">
          <template slot-scope="scope" v-if="scope.row">
            <ul>
              <div style="display: flex">
                <el-tooltip content="View Document" placement="top-start">
                  <li>
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2"
                      @click="onView(scope.row._id)"
                    >
                      <i class="el-icon-view"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <el-tooltip
                  content="Resend Document"
                  placement="top-start"
                  v-if="
                    scope.row &&
                    scope.row.document_status &&
                    (scope.row.document_status == 'SENT' ||
                      scope.row.document_status == 'APPROVER') &&
                    !scope.row.document_filling_status &&
                    checkToOnlyResend(scope.row)
                  "
                >
                  <li>
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2"
                      @click="resendDocLink(scope.row)"
                    >
                      <i class="el-icon-position"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <el-tooltip
                  content="Download Document"
                  placement="top-start"
                  v-else-if="scope.row.document_status == 'COMPLETED'"
                >
                  <li>
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2"
                      @click="onDownload(scope.row)"
                    >
                      <i class="el-icon-download"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <li>
                   *** need to work on it *** 
                  <el-dropdown>
                    <img
                      src="@/assets/img/icons/more-icon.svg"
                      alt="icon"
                      class="el-dropdown-link"
                    />
                    <el-dropdown-menu slot="dropdown">
                      <a
                        @click="alertCorrectedDoc(scope.row)"
                        v-if="
                          (checkPermissionForDocument(
                            scope.row,
                            'correctDocument'
                          ) &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.document_status != 'EXPIRED') ||
                          isfilledOrNot(scope.row)
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-checked"></i> Correct the
                          document</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="EditVoidDocument(scope.row._id)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'cloneDocument'
                          ) &&
                          (scope.row.document_status == 'VOIDED' ||
                            scope.row.document_status == 'COMPLETED')
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-copy"></i>
                          Clone</el-dropdown-item
                        >
                      </a>
                      <a> </a>
                      <a
                        @click="alertvoided(scope.row)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'voidDocument'
                          ) &&
                          scope.row &&
                          scope.row.document_status &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'DECLINED' &&
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'EXPIRED' &&
                          scope.row.document_status != 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item>
                          <i class="el-icon-remove-outline"></i>Void Document
                        </el-dropdown-item>
                      </a>
                      <a
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'deleteDocument'
                          ) && scope.row.document_status != 'ARCHIVED'
                        "
                        @click="archiveDocument(scope.row)"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-delete"></i
                          >Delete</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          scope.row &&
                          scope.row.document_status &&
                          scope.row.document_status === 'ARCHIVED'
                        "
                        @click="alertUndoDoc(scope.row)"
                      >
                        <el-dropdown-item>
                          <i class="el-icon-refresh-left"></i>Undo Delete
                        </el-dropdown-item>
                      </a>
                      <a
                        v-if="
                          scope.row &&
                          scope.row.document_status &&
                          scope.row.document_status === 'ARCHIVED'
                        "
                        @click="permanentDocument(scope.row)"
                      >
                        <el-dropdown-item>
                          <i class="el-icon-delete-solid"></i>Permanent Delete
                        </el-dropdown-item>
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                 **** need to work on it *** 
                </li>
              </div>
            </ul>
          </template>
        </el-table-column>
      </el-table> -->
      <!-- <el-table
        class="templates-table-is-mobile"
        v-if="getIsMobile && !this.isADocument"
        :total="total"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        :pagination-props="paginationProps"
        @query-change="loadData"
        :data="documentTemplatesByTemplateId"
        @sort-change="sortChange"
      >
        <el-table-column
          prop="document_status"
          label="Document Status"
          id="document_status"
          sortable="custom"
        >
          <template
            slot-scope="scope"
            v-if="scope.row && scope.row.document_status"
            style="font-size: 14px"
          >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'COMPLETED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Completed</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'VOIDED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Voided</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'SENT'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
            >
              Sent
            </span>
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'APPROVED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Approved</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'DECLINED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Declined</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'EXPIRED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Expired</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'DRAFT'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Draft</span
            >
            <span
              v-if="
                scope &&
                scope.row &&
                scope.row.document_status &&
                scope.row.document_status == 'ARCHIVED'
              "
              :class="scope.row.document_status.toLowerCase() + ' circle'"
              >Archived</span
            >
          </template>
        </el-table-column>
        <el-table-column label="Actions" width="150">
          <template slot-scope="scope" v-if="scope.row">
            <ul>
              <div style="display: flex">
                <el-tooltip content="View Document" placement="top-start">
                  <li>
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2"
                      @click="onView(scope.row._id)"
                    >
                      <i class="el-icon-view"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <el-tooltip
                  content="Resend Document"
                  placement="top-start"
                  v-if="
                    scope.row &&
                    scope.row.document_status &&
                    (scope.row.document_status == 'SENT' ||
                      scope.row.document_status == 'APPROVER') &&
                    !scope.row.document_filling_status &&
                    checkToOnlyResend(scope.row)
                  "
                >
                  <li style="margin-left: -3px">
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2"
                      @click="resendDocLink(scope.row)"
                    >
                      <i class="el-icon-position"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <el-tooltip
                  content="Download Document"
                  placement="top-start"
                  v-else-if="scope.row.document_status == 'COMPLETED'"
                >
                  <li>
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2"
                      @click="onDownload(scope.row)"
                    >
                      <i class="el-icon-download"></i>
                    </el-button>
                  </li>
                </el-tooltip>
                <li>
                 
                  <el-dropdown>
                    <img
                      style="margin-left: -3px"
                      src="@/assets/img/icons/more-icon.svg"
                      alt="icon"
                      class="el-dropdown-link"
                    />
                    <el-dropdown-menu slot="dropdown">
                      <a
                        @click="alertCorrectedDoc(scope.row)"
                        v-if="
                          (checkPermissionForDocument(
                            scope.row,
                            'correctDocument'
                          ) &&
                            scope.row.document_status != 'VOIDED' &&
                            scope.row.document_status != 'COMPLETED' &&
                            scope.row.document_status != 'ARCHIVED' &&
                            scope.row.document_status != 'EXPIRED') ||
                          isfilledOrNot(scope.row)
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-checked"></i> Correct the
                          document</el-dropdown-item
                        >
                      </a>
                      <a
                        @click="EditVoidDocument(scope.row._id)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'cloneDocument'
                          ) &&
                          (scope.row.document_status == 'VOIDED' ||
                            scope.row.document_status == 'COMPLETED')
                        "
                      >
                        <el-dropdown-item
                          ><i class="el-icon-document-copy"></i>
                          Clone</el-dropdown-item
                        >
                      </a>
                      <a> </a>
                      <a
                        @click="alertvoided(scope.row)"
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'voidDocument'
                          ) &&
                          scope.row &&
                          scope.row.document_status &&
                          scope.row.document_status != 'VOIDED' &&
                          scope.row.document_status != 'DECLINED' &&
                          scope.row.document_status != 'COMPLETED' &&
                          scope.row.document_status != 'EXPIRED' &&
                          scope.row.document_status != 'ARCHIVED'
                        "
                      >
                        <el-dropdown-item>
                          <i class="el-icon-remove-outline"></i>Void Document
                        </el-dropdown-item>
                      </a>
                      <a
                        v-if="
                          checkPermissionForDocument(
                            scope.row,
                            'deleteDocument'
                          ) && scope.row.document_status != 'ARCHIVED'
                        "
                        @click="archiveDocument(scope.row)"
                      >
                        <el-dropdown-item
                          ><i class="el-icon-delete"></i
                          >Delete</el-dropdown-item
                        >
                      </a>
                      <a
                        v-if="
                          scope.row &&
                          scope.row.document_status &&
                          scope.row.document_status === 'ARCHIVED'
                        "
                        @click="alertUndoDoc(scope.row)"
                      >
                        <el-dropdown-item>
                          <i class="el-icon-refresh-left"></i>Undo Delete
                        </el-dropdown-item>
                      </a>
                      <a
                        v-if="
                          scope.row &&
                          scope.row.document_status &&
                          scope.row.document_status === 'ARCHIVED'
                        "
                        @click="permanentDocument(scope.row)"
                      >
                        <el-dropdown-item>
                          <i class="el-icon-delete-solid"></i>Permanent Delete
                        </el-dropdown-item>
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                 
                </li>
              </div>
            </ul>
          </template>
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="expand-column-item">
              <div class="expand-column-item-label">Tracking Status:</div>
              <div class="expand-column-item-content">
                <p
                  v-if="scope.row && scope.row.created_at"
                  style="font-size: 14px"
                >
                  {{ scope.row.created_at | globalDateTimeFormat }}
                </p>
              </div>
            </div>
            <div class="expand-column-item">
              <div class="expand-column-item-label">Sent On:</div>
              <div class="expand-column-item-content">
                <template
                  v-if="
                    scope.row.document_tracking_status &&
                    scope.row.document_tracking_status.type
                  "
                >
                  <p
                    slot="reference"
                    style="font-family: inter; font-size: 14px"
                    class="text-secondary-sent"
                  >
                    {{
                      scope.row.document_tracking_status.description
                        | truncate(18, "...")
                    }}
                  </p>
                </template>
              </div>
            </div>
            <div class="expand-column-item">
              <div class="expand-column-item-label">Signed On:</div>
              <div class="expand-column-item-content">
                <template slot-scope="scope" v-if="scope.row">
                  <p
                    v-if="
                      scope.row &&
                      scope.row.document_settings &&
                      scope.row.document_settings.life_cycle_date &&
                      scope.row.document_settings.life_cycle_date.signature_date
                    "
                    style="font-size: 14px"
                  >
                    {{
                      scope.row.document_settings.life_cycle_date.signature_date
                        | globalDateFormat
                    }}
                  </p>
                  <p v-else style="text-align: center">- -</p>
                </template>
              </div>
            </div>
            <div class="expand-column-item">
              <div class="expand-column-item-label">Completed At:</div>
              <div class="expand-column-item-content">
                <template slot-scope="scope" v-if="scope.row">
                  <p
                    v-if="scope.row && scope.row.completed_at"
                    style="font-size: 14px"
                  >
                    {{ scope.row.completed_at | globalDateTimeFormat }}
                  </p>
                  <p v-else style="text-align: center">- -</p>
                </template>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table> -->
      <!-- *** end here *** (Changes need to be done)-->
      <!-- *** Pagination for documents table starts here ***-->

      <!--*** ends here ***-->
    </div>
    <el-dialog
      title="Upload a File"
      :visible.sync="uploadDocumentsInEntityProfile"
      :fullscreen="true"
      v-loading="docLoading"
      element-loading-text="Uploading Document..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :show-close="true"
    >
      <h3 class="text-center text-dark fw-normal fs-5 mb-4">
        Upload Documents
      </h3>
      <el-row class="block-types el-row-center-items select-your-preference">
        <el-col
          :xl="24"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
          style="text-align: center"
          class="select-your-preference-upload-file"
        >
          <div
            class="upload-file-container"
            style="width: 460px; height: 228px; margin-top: -20px"
          >
            <el-upload
              class="upload-file-el-upload"
              drag
              name="logo"
              :on-change="uploadFile"
              action
              :show-file-list="false"
              :auto-upload="false"
              accept=".doc, .pdf, .docx, .txt, .png, .jpg, .jpeg"
              multiple
              style="margin-top: 60px"
            >
              <div class="upload-file">
                <div class="icon py-2">
                  <img
                    src="@/assets/img/icons/upload-file.svg"
                    alt="Upload File"
                    height="40"
                    width="100"
                  />
                </div>
                <h4 class="fw-normal blue pb-2">Upload A File</h4>
                <p class="text-dark fs-9 mb-0">Drag &amp; Drop files here</p>
                <p class="text-muted fs-9 opacity-60 upload-a-file-bottom-text">
                  File Supported: PDF,DOC,JPG,PNG,TXT
                </p>
              </div>
            </el-upload>
            <div class="upload-from-other-container">
              <p
                class="
                  text-muted
                  fs-9
                  opacity-60
                  upload-a-file-upload-from-text
                "
              >
                Upload From
              </p>
              <div class="from-others">
                <div
                  class="image"
                  v-on:click="
                    (e) =>
                      handleUploadFrom(e, [
                        'googledrive',
                        'dropbox',
                        'onedrive',
                      ])
                  "
                >
                  <el-tooltip placeholder="top-start" content="Google Drive">
                    <img
                      src="@/assets/img/google-drive.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
                <div
                  class="image"
                  v-on:click="
                    (e) =>
                      handleUploadFrom(e, [
                        'dropbox',
                        'googledrive',
                        'onedrive',
                      ])
                  "
                >
                  <el-tooltip placeholder="top-start" content="Drop Box">
                    <img
                      src="@/assets/img/dropbox.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
                <div
                  class="image"
                  v-on:click="
                    (e) =>
                      handleUploadFrom(e, [
                        'onedrive',
                        'googledrive',
                        'dropbox',
                      ])
                  "
                >
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="bottom-start"
                    content="One Drive"
                  >
                    <img
                      src="@/assets/img/onedrive.svg"
                      alt="Nimble logo"
                      class="upload-icon"
                    />
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30" justify="center" class="name-types">
        <el-col :span="14">
          <draggable v-if="this.files.length" v-model="files">
            <transition-group tag="div" name="flip-list" id="field-group">
              <div
                class="filename"
                v-for="(file, index) in files"
                :key="JSON.stringify(file)"
              >
                <a class="el-upload-list__item-name">
                  <img
                    :src="require('@/assets/img/icons/upload-active.svg')"
                    alt="icon"
                    class="img-active"
                  />
                  <img
                    v-if="
                      file.raw.type == 'image/jpeg' ||
                      file.raw.type == 'image/jpg'
                    "
                    :src="require('@/assets/img/icons/JPG.svg')"
                    alt="icon"
                    class="img-fluid"
                  />
                  <img
                    v-else-if="file.raw.type == 'image/png'"
                    :src="require('@/assets/img/icons/PNG.svg')"
                    alt="icon"
                    class="img-fluid"
                  />

                  <img
                    v-else
                    :src="require('@/assets/img/icons/pdf.svg')"
                    alt="icon"
                    class="img-fluid"
                  />
                  {{ file.name }}
                </a>
                <div class="direction-buttons">
                  <a @click="deleteItem(index)">
                    <i class="el-icon-circle-close"></i>
                  </a>
                </div>
              </div>
            </transition-group>
          </draggable>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="6" :offset="4">
          <div v-if="files.length > 0">
            <el-button
              type="danger"
              class="text-center upload-btn mt-1"
              @click="uploadSingleFile()"
              >Next</el-button
            >
          </div>
        </el-col>
      </el-row>
      <!-- <el-row v-if="files.length > 0" type="flex" :gutter="30" justify="center">
        <el-col :span="12" :offset="4">
          <el-checkbox v-model="eSignatureDocument">E-Signature Document</el-checkbox>
        </el-col>
        <el-col :span="12" :offset="4">
          <el-checkbox v-model="otherDocument">Other Document</el-checkbox>
        </el-col>
      </el-row> -->
      <span slot="footer">
        <el-divider></el-divider>
        <el-button
          class="mr-2"
          type="primary"
          plain
          @click="uploadDocumentsInEntityProfile = false"
          >Close</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="Document Settings"
      :visible.sync="documentSettings"
      :show-close="true"
      v-loading="docSettingsLoading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :fullscreen="true"
      :before-close="resetUploadDocument"
    >
      <div style="margin-left: 30%">
        <h4>Life Cycle Dates</h4>
        <p>
          Set email reminders of due dates for document termination and
          renewals.
        </p>
        <div class="d-flex mt-1">
          <p>Signed on</p>
          <el-date-picker
            class="ml-1"
            placeholder="Select date"
            v-model="signatureDate"
            style="width: '100%'; display: table !important"
            v-bind:class="{
              'date-picker-item': true,
              'smaller-than': true,
            }"
            :format="
              getDefaultDateFormat &&
              getDefaultDateFormat.includes('Do') == false
                ? getDefaultDateFormat
                    .replace('YYYY', 'yyyy')
                    .replace('DD', 'dd')
                : 'MM-dd-yyyy'
            "
          ></el-date-picker>
        </div>
        <div class="d-flex mt-1">
          <p style="margin-right: 8px">Duration</p>
          <el-select
            v-model="durationValue"
            placeholder="Select Duration"
            clearable
            class="ml-1"
          >
            <el-option
              v-for="item in options"
              :key="item.durationValue"
              :label="item.label"
              :value="item.durationValue"
            >
            </el-option>
          </el-select>
          <el-input-number
            v-if="durationValue == 'For a Period Of'"
            v-model="num"
            controls-position="right"
            :min="1"
            :max="10"
            style="width: 100px"
            class="ml-1"
          ></el-input-number>
          <el-select
            v-model="durationOfYears"
            v-if="durationValue == 'For a Period Of'"
            style="width: 150px"
            class="ml-1"
            clearable
          >
            <el-option
              v-for="item in durationPeriods"
              :key="item.periodValue"
              :label="item.periodLabel"
              :value="item.periodValue"
            >
            </el-option>
          </el-select>
          <el-date-picker
            v-if="durationValue == 'Until'"
            placeholder="Select date"
            v-model="durationDate"
            style="width: '100%'; display: table !important; margin-left: 10px"
            v-bind:class="{
              'date-picker-item': true,
              'smaller-than': true,
            }"
            :format="
              getDefaultDateFormat &&
              getDefaultDateFormat.includes('Do') == false
                ? getDefaultDateFormat
                    .replace('YYYY', 'yyyy')
                    .replace('DD', 'dd')
                : 'MM-dd-yyyy'
            "
          ></el-date-picker>
        </div>
        <p
          style="color: #ebecf0"
          v-if="durationValue != 'For Permanent Duration'"
        >
          __________________________________________________________________________________________________
        </p>
      </div>
      <div
        style="margin-left: 30%"
        v-if="durationValue != 'For Permanent Duration'"
      >
        <h4>Renewal Settings</h4>
        <p>Set a renewal date and enable renewal notification.</p>
        <el-radio-group v-model="radioValue">
          <el-radio label="never_renewal" class="mt-1 mb-1">Never</el-radio
          ><br />
          <el-radio label="when_contract_ends" class="mt-1 mb-1"
            >When Contract Ends </el-radio
          ><br />
          <el-radio label="on_the_date_of" class="mt-1 d-flex"
            >On The Date Of
            <el-date-picker
              placeholder="Select date"
              v-model="onTheDateValue"
              style="
                width: '100%';
                display: table !important;
                margin-left: 64%;
                margin-top: -25px;
              "
              v-bind:class="{
                'date-picker-item': true,
                'smaller-than': true,
              }"
              :format="
                getDefaultDateFormat &&
                getDefaultDateFormat.includes('Do') == false
                  ? getDefaultDateFormat
                      .replace('YYYY', 'yyyy')
                      .replace('DD', 'dd')
                  : 'MM-dd-yyyy'
              "
            ></el-date-picker> </el-radio
          ><br />
        </el-radio-group>

        <p style="color: #ebecf0">
          ____________________________________________________________________________________________________
        </p>
      </div>
      <div
        style="margin-left: 30%; margin-top: 2px"
        v-if="durationValue != 'For Permanent Duration'"
      >
        <div v-if="radioValue != 'never_renewal'">
          <h4>Notification Email</h4>
          <el-checkbox v-model="notify_owner"
            >Notify Document Owner</el-checkbox
          >
          <el-input-number
            class="ml-1"
            style="width: 130px"
            v-model="number"
            :min="1"
          ></el-input-number>
          <el-select
            v-model="notifyDocumentRenewal"
            style="width: 150px"
            class="ml-1"
            clearable
          >
            <el-option
              v-for="item in notifyRenewal"
              :key="item.notifyValue"
              :label="item.notifyLabel"
              :value="item.notifyValue"
            >
            </el-option>
          </el-select>
          before renewal.
        </div>
      </div>
      <!-- <div style="margin-left: 30%;margin-top:4px">
        <h4>Auto reminders settings</h4>
        <p>
          Automatically send email reminders to signers regarding the
          incompletion of document.
        </p>
      </div> -->
      <span slot="footer">
        <el-divider></el-divider>
        <el-button @click="resetUploadDocument">Cancel</el-button>
        <el-button type="danger" @click="uploadDocumentToProfile()"
          >Add Document</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
//import { bus } from "../../main";
import axios from "@/config/axios";
import { mapGetters } from "vuex";
import UserTemplatesTable from "./userTemplatesTableInEntityProfiles";
import PermissionsHelper from "@/mixins/permissionsHelper";
import appConfig from "@/config/app";
import draggable from "vuedraggable";
import { fetchEntityById } from "@/repo/entityRepo";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications.js";
export default {
  name: "templates-PreviewTemplate",
  /** this is a child component for preview page these props are used in this component */
  props: [
    "entityId",
    "entityDataId",
    "isDocument",
    "this.getEntityDocumentsNotIncludingTemplatesData.total",
  ],
  /** ends here */
  mixins: [PermissionsHelper],
  components: {
    draggable,
    UserTemplatesTable,
    EntityDocumentsData: () =>
      import("@/components/entity/userProfileEntityDocumentsData"),
  },
  data() {
    return {
      radioValue: "never_renewal",
      entityDocumentTemplates: [],
      documentTemplates: [],
      employeeDocuments: [],
      step: 2,
      currentTab: "",
      eSignatureDocument: false,
      activeTabName: "",
      otherDocument: false,
      signatureDate: "",
      entityDocumentsNotIncludingTemplates: [],
      documentTemplatesByTemplateId: [],
      templates: [],
      authid: "",
      notifyDocumentRenewal: "",
      number: 1,
      activeName: "first",
      entityIdTable: this.entityId,
      entityDataIdTable: this.entityDataId,
      fileName: "",
      durationOfYears: "",
      configId: "",
      durationDate: "",
      expiraydate: null,
      isADocument: this.isDocument,
      order_type: null,
      configureLoading: false,
      total: 0,
      currentPage: 1,
      files: [],
      sendDataToTable: false,
      search_string: "",
      showDocument: false,
      pageSize: 5,
      pageVal: 5,
      page: 1,
      onTheDateValue: "",
      numberOfDuration: "",
      fields: [],
      currentFormBuilderWidth: null,
      order_by: null,
      totalDocs: null,
      loading: false,
      hasBackground: false,
      selected_page: [],
      LoadingText: "",
      uploadDocumentsInEntityProfile: false,
      docLoading: false,
      documentSettings: false,
      docSettingsLoading: false,
      backgroundImage: "",
      currentEntity : {},
      backgroundImages: [
        "https://devnimblehrms.s3.ap-south-1.amazonaws.com/company-document-backgrounds/60468b2c0a7b7942d5781c36/60468b2c0a7b7942d5781c36-0.png",
      ],
      notifyRenewal: [
        {
          notifyValue: "Year(s)",
          notifyLabel: "Year(s)",
        },
        {
          notifyValue: "Month(s)",
          notifyLabel: "Month(s)",
        },
        {
          notifyValue: "Day(s)",
          notifyLabel: "Day(s)",
        },
      ],
      durationPeriods: [
        {
          periodValue: "Year(s)",
          periodLabel: "Year(s)",
        },
        {
          periodValue: "Month(s)",
          periodLabel: "Month(s)",
        },
        {
          periodValue: "Day(s)",
          periodLabel: "Day(s)",
        },
      ],
      options: [
        {
          durationValue: "For a Period Of",
          label: "For a Period Of",
        },
        {
          durationValue: "For Permanent Duration",
          label: "For Permanent Duration",
        },
        {
          durationValue: "Until",
          label: "Until",
        },
      ],
      application_settings: {
        life_cycle_date: {
          signature_date: "",
          for_permanent_diration: false,
          for_a_period_of_duration: "",
          duration_until: "",
        },
        renewal_settings: {
          never_renewal: false,
          when_contract_ends: "",
          on_the_date_of: "",
        },
        notification_email: {
          notify_before_renewal: "",
        },
      },
      durationValue: "",
      isUploadingInDocuments: false,
      num: "",
      notify_owner: true,
      fieldsFormUpload: {
        title: "",
        type: "CUSTOM",
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
      },
    };
  },
  async mounted() {
    this.fetchEntityDocumets();
    this.fetchEmployeeDocuments();
    this.handleClickTab()
    this.currentEntity = await fetchEntityById(this.entityId);
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDataById",
      "getDocumentUploadToEntityProfileData",
      "getDocumentUploadToEntityProfileStatus",
      "getEntityDocumentsDataNotIncludingTemplatesStatus",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData",
      "getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdStatus",
    ]),
    ...mapGetters("fileUpload", [
      "getUploadFileDataStatus",
      "getUploadedPdfData",
      "getCopyDocumentStatus",
      "getCopyDocumentUrl",
    ]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getDefaultDateFormat"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    /** please don't change anything here it will be for future use*/
    ...mapGetters("documents", [
      "getResendDocumentStatus",
      "getSingleDocumentData",
      "getEmployeeDocumentsByConfigurableIdData",
      "getEmployeeDocumentsByConfigurableIdStatus",
      "getDocumentVoidStatus",
      "getDocumentCorrectStatus",
      "getCloneVoidDocStatus",
      "getCloneVoidDocData",
      "getArchiveDocumentStatus",
      "getDocumentCorrectErrors",
      "getCompanyDocumentAddStatus",
      "getNewCompanyDocument",
      "getCreateConfigureDocumentData",
      "getDraftDocumentCreateStatus",
      "getDraftDocumentCreateData",
      "getDocumentDeclineStatus",
      "getDocumentCompletedStatus",
      "getCompanyDocumentStatus",
      "getCompanyDocumentData",
      "getCompanyDocumentErrors",
    ]),
    /** upto here */
    paginationProps() {
      return [5, 10, 20, 50];
    },
    basePdfdownloadUrl() {
      // return appConfig.JAVA_BASE_URL
      return appConfig.S3_BUCKET_URL;
    },
    getBackgroundImage() {
      return (path) => appConfig.S3_BUCKET_URL + "/" + path;
    },
    getClass() {
      return (data) => {
        if (
          this.$route.query.documentTemplateId == data.configurable_document_id
        ) {
          return "list-group-item list-group-item-action active";
        } else {
          return "list-group-item list-group-item-action";
        }
      };
    },
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    handleUploadFrom(e, fromSources) {
      const client = require("filestack-js").init("AOI3ejHzTcuVrrWTbhHeaz");
      const options = {
        fromSources: fromSources,
        accept: [
          "*.doc",
          "*.pdf",
          "*.docx",
          "*.txt",
          "*.png",
          "*.jpg",
          "*.jpeg",
        ],
        allowManualRetry: true,
        transformations: {
          crop: false,
        },
        customText: {
          "Select Files to Upload": "Select Image to Upload",
        },
        onUploadDone: (res) => this.handleUploadDoneFromOthers(res),
      };
      client.picker(options).open();
    },
    handleUploadDoneFromOthers(res) {
      if (
        res.filesUploaded.length > 0 &&
        res.filesUploaded[0].url !== undefined
      ) {
        this.uploadFromOthersBlobData = null;
        this.uploadFromOthersFileName = res.filesUploaded[0].filename;
        this.loading = true;
        var self = this;
        this.toDataUrl(res.filesUploaded[0].url, function (x) {
          self.uploadFromOthersBlobData = x;
          self.loading = false;

          self.files.push(
            new self.composeNewFileItem(
              new File(
                [self.uploadFromOthersBlobData],
                self.uploadFromOthersFileName
              )
            )
          );
        });
      }
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
    prepareParams() {
      let params = {};
      params.file_upload_url = this.fileUploadUrl;
      params.file_upload_urls = this.fileUploadUrls;
      params.file_names = this.fileNames;
      params.title = this.fieldsFormUpload.selectedFileName;
      params.type = this.fieldsFormUpload.type;
      return params;
    },
    async addCompanyDocument() {
      try {
        this.loading = true;
        let params = this.prepareParams();

        if (!this.fileUploadUrl) {
          this.$message.error("Please select a document");
          return;
        }
        await this.$store.dispatch("documents/addCompanyDocument", params);
        if (this.getCompanyDocumentAddStatus) {
          await this.uploadSelectedDocument();
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    prepareDocumentUsers() {},
    async uploadSelectedDocument() {
      this.loading = true;
      this.loadingText = "Uploading Document...";
      if (this.getNewCompanyDocument) {
        await this.$store.dispatch(
          "documents/fetchConfigureDocumentIdByDocumentId",
          this.getNewCompanyDocument._id
        );
        if (this.getCreateConfigureDocumentData) {
          this.$notify.success({
            title: "Success",
            message: "Document created successfully",
          });
          await this.$store.dispatch(
            "configurableDocuments/fetchConfigurableDocumentById",
            {
              id: this.getCreateConfigureDocumentData.configurable_document._id,
            }
          );
        } else {
          this.$message("Sorry ! Error while creating configurable document");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
      this.loading = false;
    },
    async uploadDocumentToProfile() {
      try {
        if (!this.signatureDate) {
          this.$message.error("Oops.! signature date can't be empty.");
        } else {
          this.showDocument = false;
          let timeZone = this.getTimeZone();
          this.application_settings.life_cycle_date.signature_date =
            this.signatureDate;
          if (this.durationValue == "For Permanent Duration") {
            this.application_settings.life_cycle_date.for_permanent_diration = true;
          } else {
            if (this.durationValue == "Until") {
              this.application_settings.life_cycle_date.duration_until =
                this.durationDate;
            } else if (this.durationValue == "For a Period Of") {
              switch (true) {
                case this.durationOfYears == "Year(s)": {
                  const date =
                    this.application_settings.life_cycle_date.signature_date;
                  date.setFullYear(date.getFullYear() + this.num);
                  this.application_settings.life_cycle_date.for_a_period_of_duration =
                    date;
                  break;
                }
                case this.durationOfYears == "Month(s)": {
                  const date =
                    this.application_settings.life_cycle_date.signature_date;
                  date.setMonth(date.getMonth() + this.num);
                  this.application_settings.life_cycle_date.for_a_period_of_duration =
                    date;
                  break;
                }
                case this.durationOfYears == "Day(s)": {
                  const date =
                    this.application_settings.life_cycle_date.signature_date;
                  date.setDate(date.getDate() + this.num);
                  this.application_settings.life_cycle_date.for_a_period_of_duration =
                    date;
                  break;
                }
              }
            }
            switch (true) {
              case this.radioValue == "never_renewal": {
                this.application_settings.renewal_settings.never_renewal = true;
                break;
              }
              case this.radioValue == "when_contract_ends": {
                switch (true) {
                  case this.durationValue == "Until": {
                    this.application_settings.renewal_settings.when_contract_ends =
                      this.durationDate;
                    break;
                  }
                  case this.durationValue == "For a Period Of": {
                    this.application_settings.renewal_settings.when_contract_ends =
                      this.application_settings.life_cycle_date.for_a_period_of_duration;
                    break;
                  }
                }
                break;
              }
              case this.radioValue == "on_the_date_of": {
                this.application_settings.renewal_settings.on_the_date_of =
                  this.onTheDateValue;
                break;
              }
            }
            switch (true) {
              case this.notifyDocumentRenewal == "Year(s)": {
                const date =
                  this.application_settings.life_cycle_date.signature_date;
                date.setFullYear(date.getFullYear() + this.number);
                this.application_settings.notification_email.notify_before_renewal =
                  date;
                date;
                break;
              }
              case this.notifyDocumentRenewal == "Month(s)": {
                const date =
                  this.application_settings.life_cycle_date.signature_date;
                date.setMonth(date.getMonth() + this.number);
                this.application_settings.notification_email.notify_before_renewal =
                  date;
                date;
                break;
              }
              case this.notifyDocumentRenewal == "Day(s)": {
                const date =
                  this.application_settings.life_cycle_date.signature_date;
                date.setDate(date.getDate() + this.number);
                this.application_settings.notification_email.notify_before_renewal =
                  date;
                date;
                break;
              }
            }
          }
          let params;
          if (this.isADocument == true || this.isADocument) {
            params = {
              entity_id: this.entityId,
              entity_data_id: this.entityDataId,
              configurable_document_id: this.$route.query.documentTemplateId,
              document_settings: this.application_settings,
              sender_time_zone: timeZone,
              isUploadingInDocuments: true,
              document_upload_url: this.fileUploadUrls,
              name: this.fieldsFormUpload.selectedFileName,
              title: this.fieldsFormUpload.selectedFileName,
              pages: [
                {
                  page_no: 1,
                  background: this.hasBackground,
                  background_image: this.backgroundImage,
                  background_images: this.backgroundImages,
                  style:
                    "position: relative;width: 1240px;border: 1px solid #ccc;margin: 1em;max-height: 90vh;overflow: scroll;padding: 20px;",
                  fields: [],
                  page_base_width: this.currentFormBuilderWidth,
                },
              ],
            };
          } else {
            params = {
              entity_id: this.entityId,
              entity_data_id: this.entityDataId,
              configurable_document_id: this.$route.query.documentTemplateId,
              document_settings: this.application_settings,
              sender_time_zone: timeZone,
              document_upload_url: this.fileUploadUrls,
              pages: [
                {
                  page_no: 1,
                  background: this.hasBackground,
                  background_image: this.backgroundImage,
                  background_images: this.backgroundImages,
                  style:
                    "position: relative;width: 1240px;border: 1px solid #ccc;margin: 1em;max-height: 90vh;overflow: scroll;padding: 20px;",
                  fields: [],
                  page_base_width: this.currentFormBuilderWidth,
                },
              ],
            };
          }
        
          await this.$store.dispatch(
            "entities/uploadDocumentToEntityProfile",
            params
          );
          if (this.getDocumentUploadToEntityProfileStatus) {
            this.$notify.success({
              title: "Success",
              message: "Document Created Successfully.",
            });
          }
          this.$notify.success({
            title: "Success",
            message: "Document Added Successfully To Employee Profile.",
          });
          if (this.isADocument) {
            await this.fetchEntityDocumentsNotIncludeTemplates();
          } else {
            await this.fetchEmployeeDocuments();
          }
          this.resetUploadDocument();
        }
      } catch (err) {
        console.log("uploadDocumentToProfile",err);
      }
    },
    resetUploadDocument() {
      this.documentSettings = false;
      this.signatureDate = "";
      this.durationValue = "";
      this.notifyDocumentRenewal = "";
      this.durationOfYears = "";
      this.onTheDateValue = "";
    },
    uploadUserDocument() {
      this.uploadDocumentsInEntityProfile = true;
    },
    uploadFile(file) {
      this.fileName = file.name;
      if (
        file.raw.type != "audio/mpeg" &&
        file.raw.type != "video/mp4" &&
        file.raw.type != "image/gif" &&
        file.raw.type != "text/html" &&
        file.raw.type != "text/css" &&
        file.raw.type != "text/javascript" &&
        file.raw.type != "application/json" &&
        file.raw.type != "" &&
        file.raw.type != "application/x-msdownload"
      ) {
        if (file.size < 25000000) {
          file.name =
            file.name.includes(".") && file.name.split(".")[0]
              ? file.name.split(".")[0]
              : file.name;
          this.files.push(file);
        } else {
          this.$message.error("Large file.Maximum upload file size : 25 MB!");
        }
      } else {
        this.$message("File format is not supported");
      }
    },
    async deleteItem(index) {
      this.files.splice(index, 1);
    },
    async uploadSingleFile() {
      try {
        this.docLoading = true;
        var formData = new FormData();
        this.files.forEach((file, index) => {
          if (index == 0) {
            this.fieldsFormUpload.selectedFile =
              file.raw !== undefined ? file.raw : file;
            this.fieldsFormUpload.selectedFileName =
              file.raw !== undefined ? file.raw.name : file.name;
            this.fieldsFormUpload.title = file.name;
          }
          formData.append(
            "uploadedFiles",
            file.raw !== undefined ? file.raw : file
          );
        });

        let companyId =
          this.getActiveWorkspace && this.getActiveWorkspace.company_id
            ? this.getActiveWorkspace.company_id
            : this.getAuthenticatedUser._id;
        let params = {
          data: formData,
          companyId: companyId,
        };
        await this.$store.dispatch("fileUpload/uploadFilesToConvertv4", params);
        this.docLoading = false;
        if (
          this.getUploadFileDataStatus &&
          this.getUploadFileDataStatus.length
        ) {
          let tempUrl = [];
          this.getUploadFileDataStatus.forEach((file) => {
            if (file.url.includes(appConfig.S3_BUCKET_URL)) {
              tempUrl.push(file.url.replace(appConfig.S3_BUCKET_URL, ""));
            } else {
              tempUrl.push(file.url);
            }
          });
          this.fileUploadUrl = tempUrl[0];
          this.fileUploadUrls = tempUrl;
          this.fileNames = this.files.flatMap((e) => e.name);
          this.disabledButton = false;
          this.isFileIsUploaded = true;
          setTimeout(() => {
            this.$notify.success({
              title: "Success",
              message: "Document Uploaded Successfully.",
            });
          }, 300);
          this.uploadDocumentsInEntityProfile = false;
          this.documentSettings = true;
          this.files = [];
          this.addCompanyDocument();
        } else {
          this.logoError = "Error in uploading file. Please try again..";
        }
      } catch (err) {
        this.loading = false;
      }
    },
    async templateDocumentsSideMenu(data) {
      this.entityDocumentsNotIncludingTemplates = [];
      this.documentTemplatesByTemplateId = [];
      this.configureLoading = true;
      this.LoadinfText = "Fetching Document Templates...";
      this.$router.push({
        path: `/entity_data_view/${this.$route.params.entity_id}/${
          this.$route.params.template_code
        }?dataId=${this.entityDataId || ""}&activeStep=${
          this.$route.query.activeStep
        }&documentTemplateId=${data.configurable_document_id}`,
      });
      this.configId = data.configurable_document_id;
      await this.fetchEmployeeDocuments();
      this.configureLoading = false;
    },
    handleSelectionChange(val) {
      this.selected_page = [];
      this.selected_page = val;
    },
    handleClickTab() {
      this.sendDataToTable = true;
    },
    async handleClick(data, event) {
      if (event._props && event._props.label === data) {
        this.entityDocumentTemplates = [];
        this.templates = [];
        this.documentTemplatesByTemplateId = [];
        this.fetchEntityDocumets();
      } else {
        this.entityDocumentsNotIncludingTemplates = [];
        this.fetchEntityDocumentsNotIncludeTemplates();
      }
      // this.activeTabName = this.getEntityDocumentsData && this.getEntityDocumentsData[0] && this.getEntityDocumentsData[0].name
      await this.handleClickTab();
    },
    async alertUndoDoc(data) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to undo the deleted document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.archiveDocumentundo(data);
        });
      }
    },
    async permanentDocument(data) {
      let document_id = data._id;
      this.$confirm(
        "Are you sure you want to  'Permanently Delete' , Complete data of this document ?"
      )
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("documents/parmanentdelete", {
            document_id,
          });
          this.loading = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document Deleted Permanently");
            if (this.isADocument) {
              this.fetchEntityDocumentsNotIncludeTemplates();
            } else {
              this.fetchEmployeeDocuments();
            }
          } else {
            errorNotification("Error in Permanent document");
          }
        })
        .catch(() => {});
    },
    async archiveDocumentundo(data) {
      let document_id = data._id;
      this.loading = true;
      await this.$store.dispatch("documents/undoDocument", {
        document_id,
      });
      this.loading = false;
      if (this.getArchiveDocumentStatus) {
        this.$notify.success({
          title: "Success",
          message: "Document Undo successfully",
        });
        if (this.isADocument) {
          this.fetchEntityDocumentsNotIncludeTemplates();
        } else {
          this.fetchEmployeeDocuments();
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in Undo document",
        });
      }
    },
    async archiveDocument(doc) {
      let document_id = doc._id;
      this.$confirm("Are you sure to delete the document?")
        .then(async () => {
          this.loading = true;
          await this.$store.dispatch("documents/archiveDocument", {
            document_id,
          });
          this.loading = false;
          if (this.getArchiveDocumentStatus) {
            successNotification("Document deleted successfully");
            //this.fetchDocuments();
            if (this.isADocument) {
              this.fetchEntityDocumentsNotIncludeTemplates();
            } else {
              this.fetchEmployeeDocuments();
            }
          } else {
            errorNotification("Error in deleting document");
          }
        })
        .catch(() => {});
    },
    /** Documents API */
    async fetchEntityDocumentsNotIncludeTemplates() {
      try {
        if(!this.entityDataId)
          return
        this.isADocument = true;
        this.configureLoading = true;
        this.LoadingText = "Fetching Entity Documents...";
        let params = {
          limit: this.pageSize,
          page: this.page,
          entity_id: this.entityId,
          entity_data_id: this.entityDataId,
        };
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        await this.$store.dispatch(
          "entities/fetchEntitiesDocumentsNotIncludingTemplates",
          params
        );
        setTimeout(() => {
          this.configureLoading = false;
        }, 600);
        this.entityDocumentsNotIncludingTemplates =
          this.getEntityDocumentsNotIncludingTemplatesData &&
          this.getEntityDocumentsNotIncludingTemplatesData.data
            ? this.getEntityDocumentsNotIncludingTemplatesData.data
            : [];
        this.total =
          this.getEntityDocumentsNotIncludingTemplatesData &&
          this.getEntityDocumentsNotIncludingTemplatesData
            ? this.getEntityDocumentsNotIncludingTemplatesData.total
            : 0;
        this.currentPage = this.page;
      } catch (err) {
        console.log("fetchEntityDocumentsNotIncludeTemplates",err);
      }
    },
    /** ends here */
    async fetchEmployeeDocuments() {
      try {
        if(!this.entityDataId)
            return
        this.configureLoading = true;
        this.LoadingText = "Fetching Employee Documents...";
        let params = {
          limit: this.pageSize,
          page: this.page,
          entity_id: this.entityId,
          entity_data_id: this.entityDataId,
          configurable_document_id: this.configId
            ? this.configId
            : this.$route.query.documentTemplateId,
        };
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        await this.$store.dispatch(
          "entities/getEmployeeDocumentsByEntityIdAndConfigurableDocumentId",
          params
        );
        this.configureLoading = false;
        this.documentTemplatesByTemplateId =
          this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData &&
          this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData.data
            ? this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData
                .data
            : [];
        this.total =
          this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData &&
          this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData
            ? this.getEmployeeDocumentsByEntityIdAndConfigurableDocumentIdData
                .total
            : 0;
        this.currentPage = this.page;
      } catch (err) {
        console.log("fetchEmployeeDocuments",err);
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      if (this.isADocument) {
        this.fetchEntityDocumentsNotIncludeTemplates();
      } else {
        this.fetchEmployeeDocuments();
      }
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page ? data.page : 1;
        if (this.isADocument) {
          await this.fetchEntityDocumentsNotIncludeTemplates();
        } else {
          await this.fetchEmployeeDocuments();
        }
      }
    },
    handleCurrentChange(val) {
      this.page = val ? val : 1;
      if (this.isADocument) {
        this.fetchEntityDocumentsNotIncludeTemplates();
      } else {
        this.fetchEmployeeDocuments();
      }
    },
    /** template documents api */
    async fetchEntityDocumets() {
      try {
        if(!this.entityDataId)
          return
        this.configureLoading = true;
        this.LoadingText = "Fetching Entity Document Templates...";
        this.entityDocumentTemplates = [];
        this.entityDocumentsNotIncludingTemplates = [];
        this.isADocument = false;
        let params = {
          limit: this.pageVal,
          page: this.page,
          entity_id: this.entityId,
          entity_data_id: this.entityDataId,
        };
        if (this.order_by) {
          params.order_by = this.order_by;
        }
        if (this.order_type) {
          params.order_type = this.order_type;
        }
        if (this.search_string) {
          params.search_string = this.search_string;
        }
        await this.$store.dispatch("entities/fetchEntitiesDocuments", params);
        if (this.$route.query && this.$route.query.documentTemplateId) {
          await this.fetchEmployeeDocuments();
        }
        setTimeout(() => {
          this.configureLoading = false;
        }, 600);
        if (this.getEntityDocumentsDataStatus) {
          this.entityDocumentTemplates = this.getEntityDocumentsData;
          this.totalDocs = this.entityDocumentTemplates.length;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in fetching entity documents",
          });
        }
      } catch (err) {
        console.log("fetchEntityDocumets",err);
      }
    },
    /** ends here */
    async sortChange(a) {
      this.configureLoading = true;
      this.order_by = a.prop;
      this.order_type = a.order == "ascending" ? "asc" : "desc";
      if (this.isADocument) {
        await this.fetchEntityDocumentsNotIncludeTemplates();
      } else {
        await this.fetchEmployeeDocuments();
      }
      this.configureLoading = false;
      if (a.order == null) {
        this.order_by = null;
        this.order_type = null;
        if (this.isADocument) {
          await this.fetchEntityDocumentsNotIncludeTemplates();
        } else {
          await this.fetchEmployeeDocuments();
        }
      }
    },
    async alertvoided(data) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to Void the document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.DocumentVoid(data);
        });
      }
    },
    async DocumentVoid(data) {
      let document_id = data._id;
      await this.$store.dispatch("documents/voidDocument", { document_id });
      if (this.getDocumentVoidStatus) {
        this.$notify.success({
          title: "Success",
          message: "Document voided successfully",
        });
        if (this.isADocument) {
          this.fetchEntityDocumentsNotIncludeTemplates();
        } else {
          this.fetchEmployeeDocuments();
        }
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in void document",
        });
      }
    },
    onView(id) {
      let newWindow = open(
        `/emp-documents/custom/${id}`,
        "example",
        "width=1500,height=900"
      );
      newWindow.focus();
      newWindow.onload = function () {};
    },
    checkToOnlyResend(doc) {
      if (
        doc &&
        doc.document_users &&
        doc.document_users.length &&
        doc.document_users.length >= 1
      ) {
        let user = doc.document_users.forEach((x) => {
          if (
            x.sent_status &&
            !x.document_filling_status &&
            !x.is_cc &&
            x.type != "SENDER"
          ) {
            if (user) {
              return true;
            } else {
              return false;
            }
          }
        });
        return true;
      } else {
        false;
      }
    },
    async onDownload(data) {
      let url =
        this.basePdfdownloadUrl +
        (data.document_completed_url
          ? data.document_completed_url
          : data.configurable_document_data.document_upload_url);
      axios.defaults.headers.common = {
        "Access-Control-Allow-Origin": window.location.origin,
      };

      await fetch(url, { method: "get", responseType: "blob" })
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          var fileURL = window.URL.createObjectURL(new Blob([blob]));
          var fileLink = document.createElement("a");
          let file_name = data.name.split(".")[0];
          fileLink.href = fileURL;
          fileLink.setAttribute("download", file_name + ".pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },
    async EditVoidDocument(id) {
      try {
        await this.$store.dispatch("documents/EditVoidDocument", id);
        if (this.getCloneVoidDocStatus && this.getCloneVoidDocData) {
          let new_id = this.getCloneVoidDocData.data._id;
          successNotification("Document cloned successfully");
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: new_id,
            },
          });
        } else {
          this.$notify({
            title: "Error",
            message: "Error in cloning document",
            type: "error",
          });
        }
      } catch (err) {
        console.log("EditVoidDocument",err);
      }
    },
    async alertCorrectedDoc(doc) {
      if (confirm) {
        this.$confirm(
          "Are you sure you want to correct the document?",
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        ).then(() => {
          this.correctDocument(doc);
        });
      }
    },
    async correctDocument(doc) {
      try {
        let document_id = doc._id;
        await this.$store.dispatch("documents/correctDocument", document_id);
        if (this.getDocumentCorrectStatus) {
          successNotification("Document is corrected successfully");
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: document_id,
            },
          });
        } else {
          let error_message = this.getDocumentCorrectErrors
            ? this.getDocumentCorrectErrors
            : "Error while correcting the document";
          this.$notify.error({
            title: "Error",
            message: error_message,
          });
          this.$emit("reloadDashboardStats", true);
          if (this.isADocument) {
            this.fetchEntityDocumentsNotIncludeTemplates();
          } else {
            this.fetchEmployeeDocuments();
          }
        }
      } catch (err) {
        console.log("correctDocument",err);
      }
    },
    resetData() {
      this.page = 1;
      this.fetchEmployeeDocuments();
    },
    isfilledOrNot(doc) {
      if (
        doc &&
        doc.document_status &&
        doc.configurable_document_data &&
        doc.configurable_document_data[0]
      ) {
        let fields = doc.configurable_document_data.pages[0].fields.filter(
          (el) => {
            if (
              el.filled_by &&
              el.filled_by != "SENDER" &&
              el.type != "my-signature" &&
              el.type != "MY_INITIAL"
            ) {
              return el;
            }
          }
        );
        let fields_data = doc.document_data;
        let filled_fields = fields.filter((el) => {
          return fields_data[el.key].length;
        });
        if (
          filled_fields.length &&
          doc.document_status != "DECLINED" &&
          doc.document_status != "COMPLETED" &&
          doc.document_status != "ARCHIVED"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    async resendDocLink(doc) {
      let sendinguser = doc.document_users.find((x) => {
        return (
          x.sent_status &&
          !x.document_filling_status &&
          !x.is_cc &&
          x.type != "SENDER"
        );
      });
      try {
        let params = {
          document_id: doc._id,
          contact_id: sendinguser.contact_id
            ? sendinguser.contact_id
            : sendinguser.contact_id._id,
        };
        this.esignLoading = true;
        await this.$store.dispatch(
          "documents/resendDocumentLinkToContact",
          params
        );
        this.esignLoading = false;
        if (this.getResendDocumentStatus) {
          this.$notify.success({
            title: "Success",
            message: "Document Link sent successfully",
          });
        }
      } catch (err) {
        this.esignLoading = false;
        console.log("resendDocLink",err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-file-el-upload {
  @media (max-width: 991.98px) {
    .el-upload-dragger {
      height: 207px !important;
    }
  }
  .el-upload-dragger {
    border: none;
    width: 360px;
    margin-top: 52px;
  }
}
.documentsTable {
  width: 100%;
  margin-top: 10px;
}
.my-header {
  background: blue !important; // !important needed here to override default style
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="templates-table-"] {
    width: 60%;
  }
}
// .templates-table {
//   width: 58%;
//   float: right;
//   margin-top: -350px;
//   @media screen and (max-width: 1300px) {
//     width: 75%;
//   }
//   @media screen and (max-width: 1200px) {
//     width: 70%;
//   }
//   @media screen and (max-width: 1400px) {
//     width: 70%;
//   }
//   @media screen and (max-width: 1600px) {
//     width: 81%;
//   }
// }
.templates-table-is-mobile {
  width: 100%;
  // float: right;
}
.stySideMenu {
  margin-left: 10%;
}
.custom-pagination {
  margin-left: 31%;
  margin-top: 1%;
}

.templatesPagination {
  margin-left: 40%;
  margin-top: -4%;
}
.sty {
  display: flex;
}
.sidemenu {
  height: 390px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-bottom: 2px solid #f5f5f5;
  border-right: 1px ridge;
  border-left: 2px solid #f5f5f5;
  border-top: 2px solid #f5f5f5;
}
.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.list-group {
  margin-top: 15px;
  width: 255px;
  border-left: 2px #9299b8;
  border-top: 2px #9299b8;
  border-bottom: 1px ridge;
  .list-group-item {
    margin-right: -20px;
    margin-left: -35px;
    padding: 0em;
    border: none;
    color: #9299b8;
    &.active {
      color: #f754a2;
      border-left: 3px solid #f754a2;
    }
  }
}
.circle {
  position: relative;
  padding-left: 20px;
  text-transform: capitalize;
  font-size: 14px;
  &::after {
    background: #635e5e;
    content: "";
    position: absolute;
    top: 4px;
    left: 0;
    height: 10px;
    width: 10px;
    display: inline-block;
    border-radius: 50%;
  }
  &.completed::after {
    background: #08ad36;
  }
  &.draft::after {
    background: #f19203;
  }
  &.sent::after {
    background: #2f80ed;
  }
  &.active::after {
    background: #635e5e;
  }
  &.expired::after {
    background: #f03232;
  }
}
.name-types {
  margin-top: 2%;
  .filename {
    height: 56px;
    margin-top: 1%;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dfe3eb;
    box-sizing: border-box;
    border-radius: 2px;
    .el-upload-list__item-name {
      margin-top: 1em;
      .img-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }
    .direction-buttons {
      display: flex;
      visibility: visible;
      position: relative;
      top: -35%;
      left: 97%;
      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }
    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
    }
  }
}

.select-your-preference-upload-file {
  display: flex;
  justify-content: center;
  .upload-file-container {
    &:hover {
      border: 1px solid #f754a2;
    }
    @media (min-width: 991.98px) {
      min-height: 336px;
    }
    // max-width: 360px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .upload-file-el-upload {
      .block-types {
        .upload-file {
          width: 300px;
          height: 100%;
        }
      }
      .upload-a-file-bottom-text {
        border-bottom: 2px solid #ddd3d3;
        padding: 0 0 10px 0;
        margin: 0px 10px;
      }
      @media (max-width: 991.98px) {
        .el-upload-dragger {
          height: 207px !important;
        }
      }
      .el-upload-dragger {
        border: none;
        width: 300px;
        margin-top: 40px;
      }
    }
    .upload-from-other-container {
      text-align: center;
      .el-upload {
        border: none !important;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border: none;
        }
      }
      .upload-a-file-upload-from-text {
        margin-top: -30px;
      }
      .from-others {
        display: flex;
        justify-content: center;
        .image {
          cursor: pointer;
          margin-left: 10px;
          margin-right: 10px;
          .upload-icon {
            width: 25px;
            height: 25px;
          }
          @media (max-width: 991.98px) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
// .search{
//   float: right;
//   width:200px;
//   margin-right: 190px;
//   margin-bottom:-20px;
// }
</style>
